export const MeetupAPI = {
    apiKey : "PASTE_YOUR_MEETUP_API_KEY_HERE",
    urlname: "Pune-GDG" // Example GDG-Jalandhar  CHAPTER_URL_NAME
}


// PASTE_YOUR_MEETUP_API_KEY_HERE


// MEETUP_API_KEY
// For apiKey: https://secure.meetup.com/meetup_api/key/
// For urlname: extract the urlname from meetup chapter link-> https://meetup.com/GDG-Jalandhar
// Only Organizer / Co-Organizer can get the Meetup API Key
